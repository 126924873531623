interface EngagementElementProps {
  icon: JSX.Element
  title: string
  description: string
}

export const EngagementElement = ({
  title,
  description,
  icon,
}: EngagementElementProps) => {
  return (
    <div className="relative w-96 h-96 rounded-md shadow-xl hover:scale-[102%] hover:shadow-2xl transition duration-300 ease-in-out overflow-hidden">
      <div className="absolute top-[-2rem] left-[-2rem] w-16 h-16 rotate-45 bg-rose"></div>
      <div className="w-full h-full p-8 flex flex-col justify-evenly items-center gap-2">
        <div className="flex flex-col justify-center">
          <div className="w-full h-auto text-rose text-5xl flex justify-center">
            {icon}
          </div>
          <h1 className="uppercase font-bold mt-5 text-2xl text-rose">
            {title}
          </h1>
        </div>
        <p className="text-center">{description}</p>
      </div>
    </div>
  )
}
