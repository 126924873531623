import { ContactUs } from '../components/ContactUs'
import { Carousel } from '../components/Carousel'
import { motion } from 'framer-motion'
import { Engagement } from '../components/Engagement'
import { Link } from 'react-router-dom'

const images = ['3.jpg', '2.jpg', '1.jpg']

export const Welcome = () => {
  return (
    <div className="w-full h-auto">
      <Carousel images={images} autoPlay={true}>
        <motion.div
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          initial={{ y: 30, opacity: 0 }}
          transition={{ duration: 0.5, easings: 'easeInOut' }}
          className="w-full h-full flex flex-col gap-10 mt-4 md:mt-14"
        >
          <h1 className="w-full xl:w-1/2 text-2xl sm:text-3xl md:text-5xl font-bold leading-normal md:leading-tight">
            Daňové poradenství a vedení účetnictví
          </h1>
          <p className="xl:w-2/3 w-full text-sm sm:text-lg lg:text-xl">
            Vítejte na našich webových stránkách společnosti Ambition, která vám
            nabízí profesionální daňové poradenství. Jsme tady proto, abychom
            vám pomohli plnit vaše daňové povinnosti a zároveň maximalizovat
            vaše daňové úlevy. Ať už potřebujete poradit s přiznáním k dani nebo
            hledáte způsoby, jak snížit vaše daňové zatížení, jsme tu pro vás.
            Získejte jedinečný přehled o tom, jak lépe spravovat své daňové
            záležitosti.
          </p>
          <div className="flex justify-center md:justify-start w-full mt-10 lg:mt-16 pb-10">
            <Link
              className="px-6 py-3 rounded-full bg-rose text-white w-fit font-semibold border-rose border-2 hover:bg-transparent hover:text-white hover:border-white transition-all duration-300"
              to="/sluzby"
            >
              Naše nabídka
            </Link>
          </div>
        </motion.div>
      </Carousel>
      <Engagement />
      <ContactUs />
    </div>
  )
}
