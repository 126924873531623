import { TitleBanner } from '../components/TitleBanner'
import { motion } from 'framer-motion'
import { PageSubtitle } from '../components/PageSubtitle'
import { ContactBox } from '../components/ContactBox'

export const Contacts = () => {
  const handleRedirect = (url: string) => {
    window.open(url, '_blank')
  }

  return (
    <>
      <TitleBanner title="Kontakty" image="ilustrace2.jpg"></TitleBanner>
      <PageSubtitle subtitle="Neváhejte nás kontaktovat">
        <div className="flex flex-row lg:flex-nowrap flex-wrap gap-32 xl:gap-0 justify-between items-center w-full h-auto">
          <ContactBox />
          <motion.div
            whileInView={{ y: 0, opacity: 1 }}
            initial={{ y: 30, opacity: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.3, easings: 'easeInOut' }}
            className="flex w-full lg:w-1/2 justify-center items-center"
          >
            <img
              onClick={() => handleRedirect('https://www.kdpcr.cz/')}
              className="w-48 cursor-pointer"
              src="tax-org-logo.png"
              alt="tax-logo-org"
            />
          </motion.div>
        </div>
      </PageSubtitle>
    </>
  )
}
