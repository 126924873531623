export const AdressMap = () => {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2560.506124024261!2d14.404259215922457!3d50.0768102220972!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b94f99d601951%3A0x88256833a283b340!2sAmbition%2C%20s.r.o.!5e0!3m2!1scs!2scz!4v1673900945571!5m2!1scs!2scz"
      width="100%"
      height="450"
      allowFullScreen={true}
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
      title="Ambition s.r.o. - Adresa"
      className="border-none"
    ></iframe>
  )
}
