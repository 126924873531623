import { FaAngleDoubleRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

interface TitleBannerProps {
  title: string
  image: string
}

export const TitleBanner = ({ title, image }: TitleBannerProps) => {
  return (
    <>
      <div className="relative w-full h-36 md:h-64 bg-black">
        <img
          src={image}
          alt="contact-banner"
          className="absolute max-h-full z-0 object-cover w-full"
        />
        <div className="absolute w-full h-full top-0 left-0 z-10 bg-rose opacity-30"></div>
        <div className="flex flex-col items-center justify-center absolute w-full h-full text-white z-20">
          <motion.span
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            transition={{ duration: 0.5, easings: 'easeInOut' }}
            className="text-4xl md:text-6xl font-bold"
          >
            {title}
          </motion.span>
          <motion.div
            animate={{ y: 0, opacity: 1 }}
            initial={{ y: 10, opacity: 0 }}
            transition={{ duration: 0.5, easings: 'easeInOut' }}
            className="flex flex-row items-center gap-1 mt-1 md:mt-5 font-bold text-xs md:text-base"
          >
            <span className="cursor-pointer hover:text-rose transition duration-300 ease-in-out">
              <Link to="/">Úvod</Link>
            </span>
            <FaAngleDoubleRight size={12} />
            <span>{title}</span>
          </motion.div>
        </div>
      </div>
    </>
  )
}
