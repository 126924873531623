export const Companies = () => {
  return (
    <div className="flex flex-col gap-5">
      <p>
        Každá společnost je specifická ať svým podnikáním nebo právní formou.
        Máme širokou škálu klientů, nebojíme se žádného odvětví. Naši klienti
        jsou z řad:
      </p>
      <ul className="list-disc list-inside">
        <li>Akcových společností</li>
        <li>Společnestí s ručením omezeným</li>
        <li>
          Neziskového sektoru (nadace, spolky, obecně prospěšné organizace aj.)
        </li>
      </ul>
    </div>
  )
}
