import { FiChevronRight } from 'react-icons/fi'
import { motion } from 'framer-motion'

interface ServiceElementProps {
  title: string
  description: JSX.Element
  icon: JSX.Element
  expanded: boolean
  onClick: () => void
}

const variants = {
  expanded: {
    rotateZ: 90,
  },
  collapsed: {
    rotateZ: 0,
  },
  rollDown: {
    opacity: 1,
  },
  rollUp: {
    opacity: 0,
  },
}

export const ServiceElement = ({
  title,
  description,
  icon,
  expanded,
  onClick,
}: ServiceElementProps) => {
  return (
    <motion.div
      onClick={onClick}
      whileInView={{ y: [30, 0], opacity: [0, 1] }}
      viewport={{ once: true }}
      transition={{ duration: 0.6 }}
      className="flex flex-col gap-10 min-w-[300px] md:min-w-[600px] h-auto md:w-1/2 w-full shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)] flex-1 cursor-pointer rounded-lg p-6 md:p-8"
    >
      <div className="w-full h-full flex flex-row gap-3 justify-between items-center">
        <div className="flex flex-row items-center gap-5 text-rose">
          {icon}
          <span className="font-bold text-lg md:text-2xl">{title}</span>
        </div>
        <motion.div
          className="w-fit h-fit"
          variants={variants}
          animate={expanded ? 'expanded' : 'collapsed'}
        >
          <FiChevronRight className="text-rose text-3xl" />
        </motion.div>
      </div>
      {expanded && (
        <motion.div
          variants={variants}
          animate={expanded ? 'rollDown' : 'rollUp'}
          initial={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <p>{description}</p>
        </motion.div>
      )}
    </motion.div>
  )
}
