import { motion } from 'framer-motion'
import {
  HiOfficeBuilding,
  HiLocationMarker,
  HiUser,
  HiMail,
  HiPhone,
} from 'react-icons/hi'

export const ContactBox = () => {
  return (
    <motion.div
      whileInView={{ y: 0, opacity: 1 }}
      viewport={{ once: true }}
      initial={{ y: 30, opacity: 0 }}
      transition={{ duration: 0.5, easings: 'easeInOut' }}
      className="w-full md:w-2/5 h-auto md:min-w-[500px]"
    >
      <div className="flex flex-col gap-6 md:gap-2 w-full h-full">
        <div className="w-full h-auto flex flex-row items-center gap-5 md:p-3">
          <HiOfficeBuilding className="text-rose" size={24} />
          <div className="h-full w-5/6 flex flex-col gap-1 text-black/80 font-semibold text-base md:text-lg">
            <p>Ambition s.r.o.</p>
          </div>
        </div>
        <div className="w-full h-auto flex flex-row gap-5 md:p-3">
          <HiLocationMarker className="text-rose" size={24} />
          <div className="h-full w-5/6 flex flex-col gap-1 text-black/80 font-semibold text-base md:text-lg">
            <p>Pavla Švandy ze Semčic 11</p>
            <p>150 00 Praha 5 - Smíchov</p>
            <p>IČO: 28257430</p>
            <p>DIČ: CZ28257430</p>
            <p>
              Společnost zapsaná Městským soudem v Praze, oddíl C, vložka 135827
            </p>
          </div>
        </div>
        <a
          href="https://katerinadaczicka.cz/"
          target="_blank"
          rel="noreferrer"
          className="w-full h-auto flex flex-row items-center gap-5 md:hover:bg-rose/30 rounded-full md:p-3"
        >
          <HiUser className="text-rose" size={24} />
          <div className="h-full w-5/6 flex flex-col gap-1 text-black/80 font-semibold text-base md:text-lg">
            <p>Ing. Kateřina Daczická</p>
          </div>
        </a>
        <a
          target="_top"
          href={`mailto:${process.env.REACT_APP_CONTACT_MAIL}`}
          className="h-auto flex flex-row items-center gap-5 md:hover:bg-rose/30 rounded-full md:p-3"
        >
          <HiMail className="text-rose" size={24} />
          <div className="h-full w-5/6 flex flex-col gap-1 text-black/80 font-semibold text-base md:text-lg">
            <p>{process.env.REACT_APP_CONTACT_MAIL}</p>
          </div>
        </a>
        <a
          target="_top"
          href={`tel:${process.env.REACT_APP_CONTACT_PHONE}`}
          className="h-auto flex flex-row items-center gap-5 md:hover:bg-rose/30 rounded-full md:p-3"
        >
          <HiPhone className="text-rose" size={24} />
          <div className="h-full w-5/6 flex flex-col gap-1 text-black/80 font-semibold text-base md:text-lg">
            <p>{process.env.REACT_APP_CONTACT_PHONE}</p>
          </div>
        </a>
      </div>
    </motion.div>
  )
}
