export const NotFound = () => {
  return (
    <div className="flex items-center p-5 md:p-16 mt-12 overflow-hidden">
      <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
        <div className="max-w-md text-center">
          <h2 className="mb-8 font-extrabold text-7xl md:text-9xl text-rose">
            <span className="sr-only">Error</span>404
          </h2>
          <p className="text-2xl font-semibold md:text-3xl">
            Ajéje.. Tato stránka neexistuje
          </p>
          <p className="mt-4 mb-8 text-gray-700">
            Ale nevadí, můžeš se vrátit na hlavní stránku pomocí tlačítka níže a
            znovu najít cestu.
          </p>
          <a
            rel="noopener noreferrer"
            href="/"
            className="px-8 py-3 font-semibold rounded bg-rose text-white"
          >
            Hlavní stránka
          </a>
        </div>
      </div>
    </div>
  )
}
