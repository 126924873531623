import { Link } from 'react-router-dom'

interface HamburgerMenuComponentProps {
  children: string
  path: string
  onSelect: () => void
}

export const HamburgerMenuComponent = ({
  children,
  path,
  onSelect,
}: HamburgerMenuComponentProps) => {
  return (
    <div className="w-full h-16 cursor-pointer pl-4 border-b-2 last:border-0 border-white/[.5] bg-rose">
      <Link
        onClick={onSelect}
        className="flex flex-row items-center w-full h-full hover:text-white/[.8] transition duration-300 ease-in-out"
        to={path}
      >
        {children}
      </Link>
    </div>
  )
}
