import { Link } from 'react-router-dom'
import { PageSubtitle } from './PageSubtitle'
import { motion } from 'framer-motion'

export const ContactUs = () => {
  return (
    <motion.div
      initial={{ opacity: 0, x: -30 }}
      whileInView={{ opacity: 1, x: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6, easings: 'easeInOut' }}
    >
      <PageSubtitle subtitle="Neváhejte nás kontaktovat!" animation={false}>
        <>
          <p className="max-w-[1000px]">
            Rádi vám pomůžeme s jakýmikoli dotazy týkajícími se našich služeb.
            Můžete nás kontaktovat telefonicky, e-mailem nebo prostřednictvím
            našich sociálních sítí. Těšíme se na vaši zprávu!
          </p>
          <Link
            className="px-6 py-3 rounded-full bg-rose text-white w-fit font-semibold border-rose border-2 hover:bg-white hover:text-rose transition-all duration-300"
            to="/kontakt"
          >
            Kontaktovat
          </Link>
        </>
      </PageSubtitle>
    </motion.div>
  )
}
