import { useState } from 'react'
import { MdMail, MdLocalPhone, MdMenu, MdOutlineClose } from 'react-icons/md'
import { Link, useLocation } from 'react-router-dom'
import { MenuComponent } from './MenuComponent'
import { motion } from 'framer-motion'
import { HamburgerMenu } from './HamburgerMenu'

const menuItems = [
  {
    name: 'Úvod',
    path: '/',
  },
  {
    name: 'Služby',
    path: '/sluzby',
  },
  {
    name: 'O nás',
    path: '/o-nas',
  },
  {
    name: 'Kontakt',
    path: '/kontakt',
  },
]

const variants = {
  hidden: { opacity: [0, 0.5, 1] },
  visible: {
    opacity: [0, 0.5, 1],
  },
}

export const Menu = () => {
  const pathname = useLocation().pathname
  const [hamburgerMenuState, setHamburgerMenuState] = useState(false)

  const handleHamburgerMenuStateAction = () => {
    setHamburgerMenuState((current) => !current)
  }

  const handleMailTo = () => {
    window.open(`mailto:${process.env.REACT_APP_CONTACT_MAIL}`, '_blank')
  }

  const handleCallTo = () => {
    window.open(`tel:${process.env.REACT_APP_CONTACT_PHONE}`, '_top')
  }

  return (
    <>
      <div className="w-full h-24 md:h-32 bg-rose flex flex-row p-4 justify-between lg:justify-around items-center">
        <Link to="/">
          <img
            src="ambition-logo.png"
            alt="main-logo"
            className="w-32 md:w-44 lg:w-56"
          />
        </Link>
        <div className="flex flex-col items-end w-max h-full text-white font-bold">
          <div className="flex-row gap-5 hidden md:flex h-1/4">
            <div
              className="flex flex-row items-center gap-1  cursor-pointer hover:text-white/[.8] transition duration-300 ease-in-out"
              onClick={handleCallTo}
            >
              <MdLocalPhone />
              <span>{process.env.REACT_APP_CONTACT_PHONE}</span>
            </div>
            <div
              className="flex flex-row items-center gap-1 cursor-pointer hover:text-white/[.8] transition duration-300 ease-in-out"
              onClick={handleMailTo}
            >
              <MdMail />
              <span>{process.env.REACT_APP_CONTACT_MAIL}</span>
            </div>
          </div>
          <div className="hidden md:flex flex-row lg:gap-8 gap-5 w-max items-end h-3/4 pb-2">
            {menuItems.map((item) => (
              <MenuComponent
                key={item.name}
                path={item.path}
                active={pathname === item.path}
              >
                {item.name}
              </MenuComponent>
            ))}
          </div>
        </div>
        <div className="inline md:hidden text-white cursor-pointer">
          <motion.div
            animate={hamburgerMenuState ? 'visible' : 'hidden'}
            variants={variants}
            transition={{ duration: 0.5, easings: 'easeInOut' }}
          >
            {hamburgerMenuState ? (
              <MdOutlineClose
                size={30}
                onClick={handleHamburgerMenuStateAction}
              />
            ) : (
              <MdMenu size={30} onClick={handleHamburgerMenuStateAction} />
            )}
          </motion.div>
        </div>
      </div>
      <div className="w-full h-2 md:h-3 bg-rose/[.50]"></div>
      <HamburgerMenu
        menuItems={menuItems}
        menuState={hamburgerMenuState}
        onActionClose={() => setHamburgerMenuState(false)}
      />
    </>
  )
}
