import { PageSubtitle } from '../components/PageSubtitle'
import { TitleBanner } from '../components/TitleBanner'
import { ContactUs } from '../components/ContactUs'
import { motion } from 'framer-motion'

export const About = () => {
  return (
    <>
      <TitleBanner title="O nás" image="ilustrace4.jpg"></TitleBanner>
      <PageSubtitle subtitle="Ambition s.r.o.">
        <div className="flex flex-col gap-16 md:gap-24">
          <div className="flex flex-col gap-10">
            <p>
              Ambition je tým zkušených lidí jakými jsou daňový poradci, účetní
              a mzdové účetní. Od roku 2008 poskytujeme služby:
            </p>
            <ul className="list-disc list-inside">
              <li>Daňové poradenství</li>
              <li>Vedení účetnictví</li>
              <li>Vedení daňové evidence</li>
              <li>Mzdové účetnictví</li>
            </ul>
            <p>
              Snažíme se najít řešení vašeho problému a šetřit vám čas i
              finanční prostředky. Rádi se setkáváme se svými klienty osobně,
              ale pokud to nejde z jakéhokoliv důvodu setkání realizujeme i
              pomocí moderní technologie. Dobrá nálada je pro nás motorem a tuto
              pozitivní energii tak rádi předáváme na vás. Stále nejste
              rozhodnuti? Rádi předáme reference našich spokojených klientů.
              Stále nejste rozhodnuti? Rádi předáme reference našich spokojených
              klientů.
            </p>
          </div>
          <motion.div
            whileInView={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            transition={{ delay: 0.3, duration: 0.5 }}
            viewport={{ once: true }}
            className="flex flex-row justify-center items-center"
          >
            <img
              className="md:w-fit w-2/3"
              src="ambition-logo-rose.png"
              alt="ilustration"
            />
          </motion.div>
        </div>
      </PageSubtitle>
      <ContactUs />
    </>
  )
}
