export const SelfEmployed = () => {
  return (
    <div className="flex flex-col gap-5">
      <p>
        Úspory pro živnostníky a pronajímatele. Podnikáte, pronajímáte byty nebo
        máte více příjmů z různých zaměstnání? Naši odborníci jsou vždy
        připraveni vám včas zajistit:
      </p>
      <ul className="list-disc list-inside">
        <li>Evidenci příjmů a výdajů</li>
        <li>
          Maximální úspory na daních díky uplatnění všech prostředků, které
          daňové předpisy umožňují
        </li>
        <li>
          Přiznání k DPH (a další povinnosti, které z vaší činnosti plynou)
        </li>
        <li>Zpracování přiznání k dani z příjmů fyzických osob</li>
        <li>Další poradenství - vy se ptáte, my odpovídáme</li>
      </ul>
    </div>
  )
}
