import { useEffect, useState } from 'react'
import { PageSubtitle } from '../components/PageSubtitle'
import { ServiceElement } from '../components/ServiceElement'
import { TitleBanner } from '../components/TitleBanner'
import { HiOutlineDocumentDuplicate } from 'react-icons/hi'
import { BsPeople, BsCashStack } from 'react-icons/bs'
import { HiOutlineOfficeBuilding } from 'react-icons/hi'
import { TbGridDots } from 'react-icons/tb'
import { AiOutlineBank } from 'react-icons/ai'
import { Accountancy } from '../components/services/Accountancy'
import { Taxes } from '../components/services/Taxes'
import { Wages } from '../components/services/Wages'
import { Companies } from '../components/services/Companies'
import { SelfEmployed } from '../components/services/SelfEmployed'
import { OtherConsultancy } from '../components/services/OtherConsultancy'
import { ContactUs } from '../components/ContactUs'

interface Service {
  title: string
  description: JSX.Element
  icon: JSX.Element
}

const services: Service[] = [
  {
    title: 'Účetnictví',
    description: <Accountancy />,
    icon: <HiOutlineDocumentDuplicate className="text-3xl" />,
  },
  {
    title: 'Daňové poradenství',
    description: <Taxes />,
    icon: <AiOutlineBank className="text-3xl" />,
  },
  {
    title: 'Mzdy',
    description: <Wages />,
    icon: <BsCashStack className="text-3xl" />,
  },
  {
    title: 'Společnosti',
    description: <Companies />,
    icon: <HiOutlineOfficeBuilding className="text-3xl" />,
  },
  {
    title: 'OSVČ a pronajímatelé',
    description: <SelfEmployed />,
    icon: <BsPeople className="text-3xl" />,
  },
  {
    title: 'Ostatní poradentství',
    description: <OtherConsultancy />,
    icon: <TbGridDots className="text-3xl" />,
  },
]

export const Services = () => {
  const [serviceElements, setServiceElements] = useState<JSX.Element[]>([])
  const [expansion, setExpansion] = useState(
    services.map((el) => {
      return { title: el.title, state: false }
    }),
  )

  useEffect(() => {
    let result: JSX.Element[] = []

    const handleExpansion = (title: string) => {
      let newExpansion = expansion.map((el) => {
        return { ...el, state: el.title === title ? !el.state : false }
      })

      setExpansion(newExpansion)
    }

    services.forEach((service) => {
      result.push(
        <ServiceElement
          key={service.title}
          {...service}
          expanded={expansion.find((el) => el.title === service.title)!.state}
          onClick={() => handleExpansion(service.title)}
        ></ServiceElement>,
      )
    })

    setServiceElements(result)
  }, [expansion])

  return (
    <>
      <TitleBanner title="Služby" image="ilustrace3.jpg"></TitleBanner>
      <PageSubtitle subtitle="Nabízené služby">
        <div className="flex flex-row flex-wrap gap-5 w-full h-auto">
          {serviceElements}
        </div>
      </PageSubtitle>
      <ContactUs />
    </>
  )
}
