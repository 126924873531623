import { EngagementElement } from './EngagementElement'
import { PageSubtitle } from './PageSubtitle'
import { RiMedal2Line } from 'react-icons/ri'
import { VscGraph } from 'react-icons/vsc'
import { AiOutlineGlobal } from 'react-icons/ai'
import { Link } from 'react-router-dom'

export const Engagement = () => {
  return (
    <PageSubtitle subtitle="Proč si nás vybrat">
      <div className="flex flex-col justify-center items-center gap-16 my-8 md:my-16 w-full h-auto">
        <div className="flex flex-row flex-wrap xl:flex-nowrap gap-12 justify-center w-full h-auto">
          <EngagementElement
            title="Profesionalita"
            description="Poskytovatujeme svým klientům odborné a kvalitní služby, které jsou v souladu s aktuálními právními předpisy a standardy"
            icon={<RiMedal2Line />}
          />
          <EngagementElement
            title="Flexibilita"
            description="Jsme flexibilní a schopni přizpůsobit své služby individuálním potřebám a požadavkům našich klientů, abychom mohli co nejlépe vyhovět jejich potřebám"
            icon={<VscGraph />}
          />
          <EngagementElement
            title="Důvěryhodnost"
            description="Máme důraz na důvěryhodnost a transparentnost v komunikaci s klienty, aby klienti mohli mít jistotu, že jejich osobní a finanční údaje jsou v bezpečí"
            icon={<AiOutlineGlobal />}
          />
        </div>
        <div className="w-full h-auto flex justify-center">
          <Link
            className="px-6 py-3 rounded-full bg-rose text-white w-fit font-semibold border-rose border-2 hover:bg-white hover:text-rose transition-all duration-300"
            to="/sluzby"
          >
            Zjistit více
          </Link>
        </div>
      </div>
    </PageSubtitle>
  )
}
