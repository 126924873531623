import { useEffect, useState } from 'react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import { motion } from 'framer-motion'

interface CarouselProps {
  images: string[]
  autoPlay?: boolean
  children?: JSX.Element
}

export const Carousel = ({ images, autoPlay, children }: CarouselProps) => {
  const [currentImage, setCurrentImage] = useState(0)

  const handleNextImage = () => {
    if (currentImage === images.length - 1) setCurrentImage(0)
    else setCurrentImage((current) => current + 1)
  }

  const handlePreviousImage = () => {
    if (currentImage === 0) setCurrentImage(images.length - 1)
    else setCurrentImage((current) => current - 1)
  }

  useEffect(() => {
    if (autoPlay) {
      const interval = setInterval(() => {
        if (currentImage === images.length - 1) setCurrentImage(0)
        else setCurrentImage((current) => current + 1)
      }, 15000)
      return () => clearInterval(interval)
    }
  }, [autoPlay, currentImage, images])

  return (
    <div className="relative w-full md:h-[calc(100vh-8rem)] h-[calc(100vh-6rem)] min-h-[40rem]">
      <motion.img
        key={currentImage}
        animate={{ opacity: 1 }}
        initial={{ opacity: 0.5 }}
        transition={{ duration: 0.3 }}
        src={'carousel/' + images[currentImage]}
        alt="carousel"
        className="object-cover w-full h-full select-none"
      ></motion.img>
      <div className="absolute h-full w-full top-0 left-0 bg-rose/50"></div>
      <div className="flex justify-between items-center absolute top-0 left-0 w-full h-full text-white">
        <FaChevronLeft
          onClick={handlePreviousImage}
          className="text-3xl ml-5 cursor-pointer hover:text-rose hidden md:inline transition duration-300 ease-in-out"
        />
        <div className="w-full h-full flex flex-col justify-between py-10 px-8 md:px-12 lg:px-32 select-none">
          {children}
          <div className="flex flex-row h-auto w-full justify-center">
            {images.map((image, index) => (
              <div
                key={index}
                onClick={() => setCurrentImage(index)}
                className={`w-2 h-2 rounded-full mx-1 cursor-pointer ${
                  index === currentImage ? 'bg-rose' : 'bg-white'
                }`}
              ></div>
            ))}
          </div>
        </div>
        <FaChevronRight
          onClick={handleNextImage}
          className="text-3xl mr-5 cursor-pointer hover:text-rose hidden md:inline transition duration-300 ease-in-out"
        />
      </div>
    </div>
  )
}
