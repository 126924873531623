export const Wages = () => {
  return (
    <>
      <p>
        Externí zpracování mezd šetří čas a prostředky. My převezmeme
        odpovědnost za výplaty vašich zaměstnanců, vy si udržíte nižší náklady a
        100% kontrolu. Dle přání zajistíme i zpracování mezd přímo ve vašem
        sídle nebo ve vaší kanceláři. Počet zaměstnanců nerozhoduje, poradíme si
        i s velkými firmami. Kontrol od zdravotních pojišťoven a správ
        sociálního zabezpečení již nebudou vaším špatným snem, protože i toto za
        vás vyřešíme.
      </p>
    </>
  )
}
