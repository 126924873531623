import { motion } from 'framer-motion'

interface PageSubtitleProps {
  subtitle: string
  animation?: boolean
  children?: JSX.Element
}

export const PageSubtitle = ({
  subtitle,
  children,
  animation,
}: PageSubtitleProps) => {
  return (
    <div className="flex flex-col md:gap-14 gap-8 w-full h-auto px-5 py-10 md:px-16 xl:px-32 md:mt-10">
      <div className="w-fit">
        <span className="text-2xl md:text-4xl font-semibold">{subtitle}</span>
        {animation === false ? (
          <div className="w-2/5 h-1 md:h-2 mt-1 md:mt-2.5 rounded bg-rose"></div>
        ) : (
          <motion.div
            whileInView={{ scaleX: [1, 2.5, 1], x: [0, 150, 0] }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, easings: 'easeInOut' }}
            className="w-2/5 h-1 md:h-2 mt-1 md:mt-2.5 rounded bg-rose"
          ></motion.div>
        )}
      </div>
      {children}
    </div>
  )
}
