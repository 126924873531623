export const OtherConsultancy = () => {
  return (
    <div className="flex flex-col gap-5">
      <p>
        Nabízíme i další služby, ať už pomocí vlastních sil nebo ve spolupráci
        externími odborníky, které souvisí s daněmi a účetnictvím:
      </p>
      <ul className="list-disc list-inside">
        <li>Audity - doporučíme auditora a pomůžeme vám s realizací</li>
        <li>Due dilligence - prověříme společnost, kterou chcete kupovat</li>
        <li>
          Založení a likvidace společnosti - doporučíme vám právníka, který vám
          pomůže s tímto úkolem
        </li>
        <li>
          Semináře a přednášky - potřebujete vědět novinky v této oblasti a
          nechcete chodit na masová školení, i toto umíme zajistit
        </li>
      </ul>
    </div>
  )
}
