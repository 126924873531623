import { useEffect, useState } from 'react'
import { HamburgerMenuComponent } from './HamburgerMenuComponent'

interface HamburgerMenuProps {
  menuItems: { name: string; path: string }[]
  menuState: boolean
  onActionClose?: () => void
}

export const HamburgerMenu = ({
  menuItems,
  menuState,
  onActionClose,
}: HamburgerMenuProps) => {
  const [isHamburgerMenuShown, setHamburgerMenu] = useState(false)

  const handleHamburgerMenuAction = () => {
    setHamburgerMenu(false)
    if (onActionClose) onActionClose()
  }

  useEffect(() => {
    setHamburgerMenu(menuState)
  }, [menuState])

  return (
    <>
      {isHamburgerMenuShown && (
        <div className="relative inline md:hidden w-screen h-auto">
          <div className="absolute z-50 top-0 left-0 w-screen bg-rose text-white">
            <div className="flex flex-col mt-1">
              {menuItems.map((item) => (
                <HamburgerMenuComponent
                  key={item.name + '-hamburger'}
                  path={item.path}
                  onSelect={handleHamburgerMenuAction}
                >
                  {item.name}
                </HamburgerMenuComponent>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
