import { Menu } from '../components/Menu'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { Welcome } from '../pages/Welcome'
import { NotFound } from '../pages/NotFound'
import { Contacts } from './Contacts'
import { Services } from './Services'
import { About } from './About'
import { ScrollToTop } from '../components/ScrollToTop'
import { Footer } from '../components/Footer'

export const App = () => {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <div className="flex flex-col min-h-screen justify-between">
          <div className="w-full">
            <Menu />
            <Routes>
              <Route path="/" element={<Welcome />} />
              <Route path="/kontakt" element={<Contacts />} />
              <Route path="/sluzby" element={<Services />} />
              <Route path="/o-nas" element={<About />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </BrowserRouter>
    </>
  )
}
