export const Taxes = () => {
  return (
    <div className="flex flex-col gap-5">
      <p>
        Optimalizace daní je zákonem povolena, proč jí tedy nevyužít. Zpracujeme
        vaše daně a převezmeme jednání s úřady, vy tak ušetříte čas, energii a
        nervy pro své podnikání. Ať právnické nebo fyzické osoby, jsme
        připraveni pomoci v oblasti:
      </p>
      <ul className="list-disc list-inside">
        <li>Daně z příjmu právnických osob</li>
        <li>Daně z příjmu fyzických osob</li>
        <li>Daně z příjmu fyzických osob ze závislé činnosti </li>
        <li>
          Daně z přidané hodnoty (DPH) a s tím spojená další hlášení (kontrolní,
          souhrnné, OSS..)
        </li>
        <li>Daně silniční</li>
        <li>Daně z nemovitých věcí</li>
        <li>Jednání v případě kontrol ze strany státní správy</li>
      </ul>
    </div>
  )
}
