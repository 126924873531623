export const Accountancy = () => {
  return (
    <>
      <p>
        Nelamte si hlavu s čísly a papírováním, je přeci lepší si užívat než se
        stresovat. Ať už máte s.r.o., a.s., neziskovou organizaci nebo
        preferujete zpracování „bez“ papírů nebo „s“ to vše vám zajistíme, umíme
        i zajistit zpracování účetnictví přímo ve vašem sídle nebo ve vaší
        kanceláři. Ovládáme programy: Duel, Abra Flexibee, Helios inuvio, Helios
        Red, Eso9, Pohoda, ale rozhodně jsme připraveni se učit i dalším
        programům
      </p>
    </>
  )
}
