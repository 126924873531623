import { AdressMap } from './AdressMap'

export const Footer = () => {
  const handleRedirect = (url: string) => {
    window.open(url, '_blank')
  }

  return (
    <div className="flex flex-col h-auto w-full mt-10 md:mt-20">
      <AdressMap />
      <footer className="flex flex-row justify-evenly items-center p-5 px-10 w-full h-56 bg-rose text-white">
        <div className="hidden md:flex w-1/3 h-full flex-col justify-center">
          <span className="font-semibold text-lg">Kontakty:</span>
          <span>Ing. Kateřina Daczická</span>
          <a
            href="https://katerinadaczicka.cz"
            target="_blank"
            rel="noreferrer"
            className="w-fit"
          >
            katerinadaczicka.cz
          </a>
          <span>{process.env.REACT_APP_CONTACT_MAIL}</span>
          <span>{process.env.REACT_APP_CONTACT_PHONE}</span>
        </div>
        <div className="w-full md:w-1/3 h-full flex flex-col items-center justify-between">
          <img
            className="w-16"
            src="ambition-logo-small-white.png"
            alt="ambition-logo"
          />
          <div className="w-full h-auto flex flex-col gap-3 items-center">
            <span className="font-semibold text-lg">Ambition s.r.o.</span>
            <div className="flex flex-col items-center text-white/70 text-center md:text-base text-xs">
              <span>© Copyright 2011-{new Date().getFullYear()}</span>
              <span>All rights reserved. Created by Adam Svoboda</span>
            </div>
          </div>
        </div>
        <div className="hidden w-1/3 h-full md:flex justify-end items-center">
          <img
            onClick={() => handleRedirect('https://www.kdpcr.cz/')}
            className="h-2/3 cursor-pointer"
            src="tax-org-logo.png"
            alt="tax-org-logo"
          ></img>
        </div>
      </footer>
    </div>
  )
}
